import React, { useCallback, useEffect, useRef } from "react";
import Navbar from "components/Navbar/navbar";
import AuthComponent from "components/Auth/backend_auth"; // Assuming this is a component
import DarkTheme from "layouts/Dark";
import { useAuth } from "../../authContext"; // Make sure the path is correct
import { navigate } from "gatsby"; // Import navigate from gatsby
import particlesConfig from "config/particle-config";
import particlesBlackConfig from "config/pr-s-black";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

const PlatformAuth = ({ blackStar, location }) => {
  console.log("Location: ", location);
  const navbarRef = useRef(null);
  const logoRef = useRef(null);
  const { user, isLoading, isAuthenticated } = useAuth();

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
    document
      .querySelector("#particles-js canvas")
      ?.style.removeProperty("position");
  }, []);

  useEffect(() => {
    var navbar = navbarRef.current;
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll); // Cleanup on unmount
  }, [isAuthenticated, user, navigate]); // Add dependencies here

  // If loading, you may want to display a loading indicator or similar
  if (isLoading || isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <header className="circle-bg valign documents-view auth-view-circle-desktop">
        <div className="gradient-circle"></div>
      </header>
      <AuthComponent landOnSignUp={location?.state?.signUp} />
      <Particles
        id="particles-js"
        init={particlesInit}
        options={blackStar ? particlesBlackConfig : particlesConfig}
      />
      <header className="circle-bg valign documents-view">
        <div className="gradient-circle two"></div>
      </header>
    </DarkTheme>
  );
};

export default PlatformAuth;
